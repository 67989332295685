<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">

      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Prescription Orders</h3>
          <div>
            <router-link :to="{name: 'merchant_create_prescription_order'}" class="ps-btn btn-sm"><i class="icon-cart-add"></i>  Create</router-link>
          </div>
        </div>
        <div class="row mt-3 mb-3" v-if="message">
          <div class="col-12">
            <small class="text-danger" v-if="isError">{{ message }}</small>
            <small class="text-success" v-else>{{ message }}</small>
          </div>
        </div>
        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor">
              <thead>
              <tr>
                <th>Reference</th>
                <th class="text-right">Total Amount</th>
                <th>Status</th>
                <th class="text-center">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td>
                  <router-link :to="{name: 'merchant_prescription_order_detail', params: {id: order.id}}">{{ order.order_number }}</router-link>
                </td>
                <td class="text-right">{{ order.total_amount | toCurrency }}</td>
                <td>
                  <span v-if="order.status.name === 'pending'" class="text-warning">{{ order.status.label }} </span>
                  <span v-if="order.status.name === 'paid'" class="text-success">{{ order.status.label }} </span>
                  <span v-if="order.status.name === 'approved'" class="text-danger">{{ order.status.label }} </span>
                </td>
                <td class="text-center">
                  <a @click="deleteOrder(order.id)" href="#">
                    <i v-if="!loading" class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
              </tbody>
              <tfoot>
                <tr v-if="(!meta || meta.total === 0) && !isLoading">
                  <td colspan="6" class="text-center m-4 text-danger p-4">No prescriptions orders available</td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="6" class="text-center m-4 text-danger">Loading orders...</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "PrescriptionOrdersList",
  data() {
    return {
      orders: [],
      meta: null,
      isLoading: false,
      isError: false,
      message: null
    }
  },
  mounted() {
    this.getPrescriptionOrders()
  },
  methods: {
    getPrescriptionOrders() {
      this.isLoading = true;
      axios.get('orders/prescriptions').then((response) => {
         this.orders = response.data.data;
         this.meta = response.data.meta;
         this.isLoading = false;
      }).catch((error) => {
         this.isLoading = false;
      });
    },
    deleteOrder(id) {
      if (confirm('Are you sure you want to delete this prescription order')) {
          this.isLoading = true;
          axios.delete('orders/prescriptions/' + id).then((response) => {
            this.getPrescriptionOrders();
            this.message = 'Prescription order deleted successfully';
          })
          .catch((error) => {
            this.isLoading = false;
            this.isError = true;
            this.message = 'An error occurred deleting the prescription order';
          });
      }
    }
  }
}
</script>

<style scoped>

</style>